import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/travis/build/reach/reach-ui/website/src/components/mdx-layout.js";
import SEO from "../components/SEO";
import { TOC, TOCList, TOCLink } from "../components/TOC";
import { AsPropWarning } from "../components/AsPropWarning";
import { Pipe } from "../components/Pipe";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Disclosure" description="Accessible disclosure component for React" mdxType="SEO" />
    <h1 {...{
      "id": "disclosure"
    }}>{`Disclosure`}</h1>
    <TOC mdxType="TOC">
  <TOCList mdxType="TOCList">
    <TOCLink href="#disclosure-1" mdxType="TOCLink">Disclosure</TOCLink>
    <TOCLink href="#disclosurebutton" mdxType="TOCLink">DisclosureButton</TOCLink>
    <TOCLink href="#disclosurepanel" mdxType="TOCLink">DisclosurePanel</TOCLink>
    <TOCLink href="#usedisclosurecontext" mdxType="TOCLink">useDisclosureContext</TOCLink>
  </TOCList>
    </TOC>
    <ul>
      <li parentName="ul">{`Source: `}<a parentName="li" {...{
          "href": "https://github.com/reach/reach-ui/tree/main/packages/disclosure"
        }}>{`https://github.com/reach/reach-ui/tree/main/packages/disclosure`}</a></li>
      <li parentName="ul">{`WAI-ARIA: `}<a parentName="li" {...{
          "href": "https://www.w3.org/TR/wai-aria-practices-1.2/#disclosure"
        }}>{`https://www.w3.org/TR/wai-aria-practices-1.2/#disclosure`}</a></li>
    </ul>
    <p>{`A disclosure is a button that controls the visibility of a panel of content. When the content inside the panel is hidden, it is often styled as a typical push button with a right-pointing arrow or triangle to hint that activating the button will display additional content. When the content is visible, the arrow or triangle typically points down.`}</p>
    <p>{`If you have a group of disclosures that stack vertically and exist within the same logical context, you may want to use `}<a parentName="p" {...{
        "href": "/accordion"
      }}><inlineCode parentName="a">{`@reach/accordion`}</inlineCode></a>{` instead.`}</p>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <p>{`From the command line in your project directory, run `}<inlineCode parentName="p">{`npm install @reach/disclosure`}</inlineCode>{` or `}<inlineCode parentName="p">{`yarn add @reach/disclosure`}</inlineCode>{`. Then import the components that you need:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @reach/disclosure
# or
yarn add @reach/disclosure
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@reach/disclosure";
`}</code></pre>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return (
    <Disclosure>
      <DisclosureButton>Find out what lies beneath</DisclosureButton>
      <DisclosurePanel>Here I am! I am the buried treasure!</DisclosurePanel>
    </Disclosure>
  );
}
`}</code></pre>
    <h2 {...{
      "id": "component-api"
    }}>{`Component API`}</h2>
    <h3 {...{
      "id": "disclosure-1"
    }}>{`Disclosure`}</h3>
    <p>{`The wrapper component and context provider for a disclosure's button and panel components. A disclosure should never have more than one button or panel descendants.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Disclosure>
  <DisclosureButton>Do a thing</DisclosureButton>
  <DisclosurePanel>...</DisclosurePanel>
</Disclosure>
`}</code></pre>
    <p>{`It is important to note that the `}<inlineCode parentName="p">{`Disclosure`}</inlineCode>{` component doesn't actually render a DOM node, so there is no way to add styles to a disclosure wrapper directly. If you want your disclosure to have a wrapper element you can nest one directly.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Disclosure>
  <div style={{ padding: 10 }}>
    <DisclosureButton>Do a thing</DisclosureButton>
    <DisclosurePanel>...</DisclosurePanel>
  </div>
</Disclosure>
`}</code></pre>
    <h4 {...{
      "id": "controlled-disclosure"
    }}>{`Controlled Disclosure`}</h4>
    <p>{`If you want to control the disclosure's value, you can do so by passing `}<a parentName="p" {...{
        "href": "#disclosure-open"
      }}><inlineCode parentName="a">{`open`}</inlineCode></a>{` and `}<a parentName="p" {...{
        "href": "#disclosure-onchange"
      }}><inlineCode parentName="a">{`onChange`}</inlineCode></a>{` props.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const [isOpen, setOpen] = React.useState(false);
return (
  <Disclosure open={isOpen} onChange={() => setOpen(!isOpen)}>
    <DisclosureButton />
    <DisclosurePanel />
  </Disclosure>
);
`}</code></pre>
    <h4 {...{
      "id": "disclosure-props"
    }}>{`Disclosure Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#disclosure-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#disclosure-defaultopen"
            }}><inlineCode parentName="a">{`defaultOpen`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#disclosure-id"
            }}><inlineCode parentName="a">{`id`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode>{` `}<Pipe mdxType="Pipe" />{` `}<inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#disclosure-onchange"
            }}><inlineCode parentName="a">{`onChange`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#disclosure-open"
            }}><inlineCode parentName="a">{`open`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "disclosure-children"
    }}>{`Disclosure children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode`}</inlineCode></p>
    <p><inlineCode parentName="p">{`Disclosure`}</inlineCode>{` expects to receive accept `}<inlineCode parentName="p">{`DisclosureButton`}</inlineCode>{` and `}<inlineCode parentName="p">{`DisclosurePanel`}</inlineCode>{` components as either direct children or descendants. It can also accept wrapper elements if desired, though it is not recommended to pass other arbitrary components within a disclosure in most cases.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<div>
  {/* OK! */}
  <Disclosure>
    <DisclosureButton>Click Me</DisclosureButton>
    <DisclosurePanel>Collapse or open this content!</DisclosurePanel>
  </Disclosure>

  {/* Also OK! */}
  <Disclosure>
    <div>
      <DisclosureButton>Click Me</DisclosureButton>
      <DisclosurePanel>Collapse or open this content!</DisclosurePanel>
    </div>
  </Disclosure>

  {/* Probably confusing, you should avoid! */}
  <Disclosure>
    <div>Mary had a little lamb, little lamb, blah blah blah</div>
    <DisclosureButton>Click Me</DisclosureButton>
    <DisclosurePanel>Collapse or open this content!</DisclosurePanel>
    <blockquote>You miss 100% of the shots you don't take</blockquote>
  </Disclosure>
</div>
`}</code></pre>
    <h5 {...{
      "id": "disclosure-defaultopen"
    }}>{`Disclosure defaultOpen`}</h5>
    <p><inlineCode parentName="p">{`defaultOpen?: boolean`}</inlineCode></p>
    <p>{`Whether or not an uncontrolled disclosure component should default to its `}<inlineCode parentName="p">{`open`}</inlineCode>{` state on the initial render. Defaults to `}<inlineCode parentName="p">{`false`}</inlineCode>{`.`}</p>
    <h5 {...{
      "id": "disclosure-id"
    }}>{`Disclosure id`}</h5>
    <p><inlineCode parentName="p">{`id?: string | number`}</inlineCode></p>
    <p>{`An id used to assign aria and id attributes to nested `}<inlineCode parentName="p">{`DisclosureButton`}</inlineCode>{` and `}<inlineCode parentName="p">{`DisclosurePanel`}</inlineCode>{` components.`}</p>
    <p>{`Since the Disclosure component itself does not render a DOM element, an `}<inlineCode parentName="p">{`id`}</inlineCode>{` prop will not appear in the DOM directly as may be expected. Rather, we need to generate IDs for the panel and button based on a disclosure ID for aria compliance. If no `}<inlineCode parentName="p">{`id`}</inlineCode>{` is passed we will generate descendant IDs for you.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Disclosure id="awesome-disclosure">
  <div>
    {" "}
    {/* no ID passed here! */}
    <DisclosureButton /> {/* <button id="awesome-disclosure--button" /> */}
    <DisclosurePanel /> {/* <div id="awesome-disclosure--panel" /> */}
  </div>
</Disclosure>
`}</code></pre>
    <h5 {...{
      "id": "disclosure-onchange"
    }}>{`Disclosure onChange`}</h5>
    <p><inlineCode parentName="p">{`onChange?: () => void`}</inlineCode></p>
    <p>{`The callback that is fired when a disclosure's open state is changed.`}</p>
    <h5 {...{
      "id": "disclosure-open"
    }}>{`Disclosure open`}</h5>
    <p><inlineCode parentName="p">{`open?: boolean`}</inlineCode></p>
    <p>{`The controlled open state of the disclosure. The `}<inlineCode parentName="p">{`open`}</inlineCode>{` prop should be used along with `}<inlineCode parentName="p">{`onChange`}</inlineCode>{` to create controlled disclosure components.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const [open, setOpen] = React.useState(false);
return (
  <Disclosure open={open} onChange={() => setOpen(!open)}>
    <DisclosureButton>I have a secret</DisclosureButton>
    <DisclosurePanel>
      Ante rhoncus facilisis iaculis nostra faucibus vehicula ac consectetur
      pretium, lacus nunc consequat id viverra facilisi ligula eleifend, congue
      gravida malesuada proin scelerisque luctus est convallis.
    </DisclosurePanel>
  </Disclosure>
);
`}</code></pre>
    <h3 {...{
      "id": "disclosurebutton"
    }}>{`DisclosureButton`}</h3>
    <p>{`The trigger button a user clicks to interact with a disclosure.`}</p>
    <h4 {...{
      "id": "disclosurebutton-css-selectors"
    }}>{`DisclosureButton CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-disclosure-button] {
}
[data-reach-disclosure-button][data-state="open"] {
}
[data-reach-disclosure-button][data-state="collapsed"] {
}
`}</code></pre>
    <h4 {...{
      "id": "disclosurebutton-props"
    }}>{`DisclosureButton Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#disclosurebutton-as"
            }}><inlineCode parentName="a">{`as`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode>{` `}<Pipe mdxType="Pipe" />{` `}<inlineCode parentName="td">{`Component`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#disclosurebutton-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "disclosurebutton-as"
    }}>{`DisclosureButton as`}</h5>
    <p><inlineCode parentName="p">{`as?: keyof JSX.IntrinsicElements | React.ComponentType`}</inlineCode></p>
    <p>{`A string representing an HTML element or a React component that will tell the `}<inlineCode parentName="p">{`DisclosureButton`}</inlineCode>{` what element to render. Defaults to `}<inlineCode parentName="p">{`button`}</inlineCode>{`.`}</p>
    <AsPropWarning mdxType="AsPropWarning" />
    <h5 {...{
      "id": "disclosurebutton-children"
    }}>{`DisclosureButton children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode`}</inlineCode></p>
    <p>{`Typically a text string that serves as a label for the disclosure button, though nested DOM nodes can be passed as well so long as they are valid children of interactive elements.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Further reading:`}</strong>{` `}<a parentName="li" {...{
          "href": "https://adrianroselli.com/2016/12/be-wary-of-nesting-roles.html"
        }}><em parentName="a">{`Be Wary of Nesting Roles`}</em>{` by Adrian Roselli`}</a></li>
    </ul>
    <h3 {...{
      "id": "disclosurepanel"
    }}>{`DisclosurePanel`}</h3>
    <p>{`The collapsible panel in which inner content for a disclosure item is rendered.`}</p>
    <h4 {...{
      "id": "disclosurepanel-css-selectors"
    }}>{`DisclosurePanel CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-disclosure-panel] {
}
[data-reach-disclosure-panel][data-state="open"] {
}
[data-reach-disclosure-panel][data-state="collapsed"] {
}
`}</code></pre>
    <h4 {...{
      "id": "disclosurepanel-props"
    }}>{`DisclosurePanel Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#disclosurepanel-as"
            }}><inlineCode parentName="a">{`as`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode>{` `}<Pipe mdxType="Pipe" />{` `}<inlineCode parentName="td">{`Component`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#disclosurepanel-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "disclosurepanel-as"
    }}>{`DisclosurePanel as`}</h5>
    <p><inlineCode parentName="p">{`as?: keyof JSX.IntrinsicElements | React.ComponentType`}</inlineCode></p>
    <p>{`A string representing an HTML element or a React component that will tell the `}<inlineCode parentName="p">{`DisclosurePanel`}</inlineCode>{` what element to render. Defaults to `}<inlineCode parentName="p">{`div`}</inlineCode>{`.`}</p>
    <AsPropWarning mdxType="AsPropWarning" />
    <h5 {...{
      "id": "disclosurepanel-children"
    }}>{`DisclosurePanel children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode`}</inlineCode></p>
    <p>{`Inner collapsible content for the disclosure item.`}</p>
    <h3 {...{
      "id": "usedisclosurecontext"
    }}>{`useDisclosureContext`}</h3>
    <p><inlineCode parentName="p">{`function useDisclosureContext(): { id: string | undefined; panelId: string; open: boolean }`}</inlineCode></p>
    <p>{`A hook that exposes data for a given `}<inlineCode parentName="p">{`Disclosure`}</inlineCode>{` component to its descendants.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      